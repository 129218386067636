import React from "react"
import Layout from "../components/layout"

const PageNotFound = () => {
  return (
    <Layout>
        <div className="row">
          <div className="col-lg-10">
            <h1 style={{ paddingTop: "15%" }}>Page not found.</h1>
          </div>
        </div>
    </Layout>
  )
}

export default PageNotFound

